import "./assets/scss/index.scss";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect, Suspense, lazy } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom"; /* 
import Dashboard from "./components/Dashboard";
import DashboardLayout from "./components/DashboardLayout";
import { Test } from "./components/Test";
import InnerPageLayout from "./components/InnerPageLayout";
import { Nav as SalesAndInvoicingNav } from "./components/SalesAndInvoicing/Nav";
import { InvoiceList } from "./components/SalesAndInvoicing/InvoiceList";
import Login from "./components/Login";
import HomeLayout from "./components/HomeLayout";
import MasterLayout from "./components/MasterLayout";
import Hello from "./components/utils/Hello";
import DetailedIcon, {
  BucketIcon,
  PageHeaderListIcon,
  WareHouseIcon,
} from "./components/Icons";
import Accounts from "./components/Dashboard/Accounts";
import System from "./components/Dashboard/System";
import { Wrapper as SalesAndInvoicingWrapper } from "./components/SalesAndInvoicing/Wrapper";
import CreateInvoice from "./components/SalesAndInvoicing/CreateInvoice";
import ReportsNav from "./components/Reports/ReportsNav";
import CashBook from "./components/Reports/CashBook";
import SalesAnalysis from "./components/Reports/SalesAnalysis";
import SalesRepReports from "./components/Reports/SalesRepReports/SalesRepReports";
import Sales from "./components/Reports/SalesRepReports/Sales";
import Nav from "./components/Reports/SalesRepReports/Nav";
import Customers from "./components/Reports/SalesRepReports/Customers";
import SalesByInvoice from "./components/Reports/SalesByInvoice";
import PaymentReport from "./components/Reports/PaymentReport";
import Debtor from "./components/Reports/SalesRepReports/Debtor";
import Payment from "./components/Reports/SalesRepReports/Payment";
import TransactionAnalysis from "./components/Reports/TransactionAnalysis";
import CustomerTransactionAnalysis from "./components/Reports/CustomerTransactionAnalysis";
import CustomerLedger from "./components/Reports/CustomerLedger";
import LoyaltyCustomers from "./components/Reports/LoyaltyCustomers";
import AccountsPayables from "./components/Reports/AccountsPayables";
import AccountsReceivables from "./components/Reports/AccountsReceivables";
import PurchaseHistory from "./components/Reports/PurchaseHistory";
import StockPositionByDate from "./components/Reports/StockPositionByDate";
import InventoryAnalysis from "./components/Reports/InventoryAnalysis";
import InventoryAnalysisWarehouse from "./components/Reports/InventoryAnalysisWarehouse";
import PrintInventoryByProduct from "./components/Reports/PrintInventoryByProduct";
import PostVendorsPayment from "./components/Vendor/PostVendorsPayment";
import VendorsLedger from "./components/Vendor/VendorsLedger";
import Expenses from "./components/Reports/Expenses";
import BankAccountAnalysis from "./components/Reports/BankAccountAnalysis";
import GeneralLedger from "./components/Reports/GeneralLedger";
import TrialBalance from "./components/Reports/TrialBalance";
import IncomeStatement from "./components/Reports/IncomeStatement";
import BalanceSheet from "./components/Reports/BalanceSheet";
import Send from "./components/Requisition/Send";
import Request from "./components/Requisition/Request";
import RequisitionNav from "./components/Requisition/RequisitionNav";
import RequisitionWrapper from "./components/Requisition/RequisitionWrapper";
import DetailsModal from "./components/Requisition/DetailsModal";
import PreviewModal from "./components/Requisition/PreviewModal";
import ApproveAndSendModal from "./components/Requisition/ApproveAndSendModal";
import ConversationsModal from "./components/Requisition/Conversation/ConversationsModal";

import SalesInventory from "./components/SalesInventory";
import ApprovalIndex from "./components/Approval/ApprovalIndex";
import ApproveInvoiceModal from "./components/Approval/ApproveInvoiceModal";
import { CustomerList } from "./components/SalesAndInvoicing/CustomerList";
import CreateCustomersInvoice from "./components/SalesAndInvoicing/CreateCustomersInvoice";
import CreateMeterInvoice from "./components/SalesAndInvoicing/CreateMeterInvoice";
import CreateIronRodInvoice from "./components/SalesAndInvoicing/CreateIronRodInvoice";*/
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "./hooks/useAuth";
import Cookies from "universal-cookie";
import { NoMatch } from "./components/utils/NoMatch";
import {
  useEffectOnce,
  useIsAdmin,
} from "./utils/hooks"; /*
import LoadAtc from "./components/LoadAtc";
import TransportReport from "./components/Reports/TransportReport";
import TransportRevenueEstimates from "./components/Reports/TransportRevenueEstimates";

import DriverSalary from "./components/Salary/DriverSalary";
import Incentive from "./components/Incentives/Incentive";
import { InventoryList } from "./components/Inventory/InventoryList";
import { InventoryNav } from "./components/Inventory/InventoryNav";
import InventoryListEntry from "./components/Inventory/InventoryListEntry";
import SupplyCenter from "./components/SupplyCenter";
import ReturnItem from "./components/SalesAndInvoicing/ReturnItem";
import PostPayment from "./components/PostPayment";
import CementPriceSettings from "./components/CementPriceSettings";
import CreateProformaInvoice from "./components/SalesAndInvoicing/CreateProformaInvoice";
import { ItemIssuer } from "./components/Inventory/ItemIssuer";
import ReceiveInventory from "./components/Receive/ReceiveInventory";
import DeleteInvoice from "./components/SalesAndInvoicing/DeleteInvoice";
import ReturnItemFromBranch from "./components/Inventory/ReturnItemFromBranch";
import { InventoryManagerNav } from "./components/Inventory/InventoryManagerNav";
import DamagesReturnRecorder from "./components/Inventory/DamagesReturnRecorder";
import ExpensesEntry from "./components/ExpensesEntry";
import CreditDebitMemo from "./components/creditDebitMemo";
import CreateCreditDebitMemo from "./components/CreateCreditDebitMemo";
import Authorize from "./components/Authorize";
import ChartOfAccount from "./components/Maintenance/ChartOfAccount";
import Account from "./components/Maintenance/Account";
import ChartOfAccountImprest from "./components/Maintenance/ChartOfAccountImprest";
import JournalEntry from "./components/JournalEntry";
import ManageTransaction from "./components/SalesAndInvoicing/ManageTransaction";
import Cashes from "./components/Reports/Cashes";
import EditProformaInvoice from "./components/SalesAndInvoicing/EditProformaInvoice";
import StockRecords from "./components/Reports/StockRecords";
import UserManagemnt from "./components/Dashboard/UserManagement";
import CompanyInformationSettings from "./components/Dashboard/CompanyInformationSettings";
import SubNavs from "./components/SubNavs";
import { wareHouseChildren } from "./config";
import WareHouse from "./components/warehouse/warehouse";
import WarehouseInventoryListEntry from "./components/warehouse/stock/WarehouseInventoryListEntry";
import { WarehouseInventoryNav } from "./components/warehouse/WarehouseInventoryNav";
import StorageLocation from "./components/warehouse/StorageLocation";
import WarehouseChangeSide from "./components/warehouse/WarehouseChangeSide";
import { WarehouseIssuesItem } from "./components/warehouse/WarehouseIssueItem";
import ReceiveItemWarehouse from "./components/warehouse/ReceiveItemWarehouse";
import { WarehouseMaintance } from "./components/warehouse/WarehouseMaintance";
import { VendorsInventoryNav } from "./components/Vendor/VendorInventoryNav";
import Vendors from "./components/Vendor/Vendors";
import VendorCreditDebitMemo from "./components/Vendor/VendorCreditDebitMemo";
import CreateVendorCreditDebitMemo from "./components/Vendor/CreateVendorCreditDebitMemo";
import { VendorWrapper } from "./components/Vendor/VendorWapper";
import CreateRefund from "./components/Refund";
import Plants from "./components/Production/Plants"; */

/* import { FeedBack } from "./components/FeedBack";
import { ThankYouFeedBack } from "./components/ThankYouFeedBAck"; */

const FeedBack = lazy(() => import("./components/FeedBack"));
const ThankYouFeedBack = lazy(() => import("./components/ThankYouFeedBAck"));

function App() {
  let location = useLocation();
  const isAdmin = useIsAdmin();

  const {
    isTransportAndLogistics,
    isIronRod,
    isCement,
    isOilAndGas,
    user: authUser,
  } = useAuth();

  let state = location?.state;
  // let url = new URL(document.location.href);

  // Get company from url first, next get from state
  // then set company here before we render our content

  /*  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  }, [location]); */

  /*   useEffectOnce(() => {
    let url = new URL(document.location.href);
    url = searchParamsToObject(url.searchParams.entries());

    // fot gigc
    if (url.authorize && !appSettings.isBatchStandard) {
      if (isEmpty(authUser)) {
      }
    }
  }); */

  useEffectOnce(() => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("x-access-token");
      if (token && authUser?.token) {
        cookies.set("x-access-token", authUser.token, { path: "/" });
      }
    } catch (err) {
      console.log(err);
    }
  });

  return (
    <div className="app" key={authUser?.company}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
     
        <Routes>
          <Route path="">
            <Route index element={<Navigate to="user-feedBack" />} />
            <Route path="user-feedBack" element={<FeedBack />} />
            <Route path="feedback-thankyou" element={<ThankYouFeedBack />} />
          </Route>
          <Route path="*" element={<NoMatch />} />{" "}
        </Routes>
      
    </div>
  );
}

export default App;
