import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { backendApis } from "../config";
import { searchParamsToObject } from "./helpers";

// Hook
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useAsyncReference(value, isProp = false) {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    if (!Object.is(ref.current, newState)) {
      ref.current = newState;
      forceRender((s) => !s);
    }
  }

  if (isProp) {
    ref.current = value;
    return ref;
  }

  return [ref.current, updateState];
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function ScrollOnLocationChange() {
  let location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 0);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      0
    );
  }, [location]);

  return null;
}

export function ScrollOnParamsChange(params) {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 0);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      0
    );
  }, [params]);

  return null;
}

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

export function useQueryParams(
  defaultParams = {
    page: 1,
    limit: 40,
  }
) {
  let url = new URL(document.location.href);
  url = searchParamsToObject(url.searchParams.entries());
  let requiredQueryParams = defaultParams;

  const initialQueryParams = !isEmpty(url)
    ? { ...requiredQueryParams, ...url }
    : requiredQueryParams;

  const [searchQuery, setSearchQuery] = useSearchParams(initialQueryParams);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const debouncedQueryParams = useDebounce(queryParams, 500);
  useEffect(() => {
    setSearchQuery(debouncedQueryParams, { replace: true });
  }, [debouncedQueryParams]);

  return [queryParams, setQueryParams];
}

export default useDebounce;

// listen history change and store it
export function useHistoryStack() {
  const [stack, setStack] = useState([]);
  const { pathname } = useLocation();
  const type = useNavigationType();
  useEffect(() => {
    if (type === "POP") {
      setStack(stack.slice(0, stack.length - 1));
    } else if (type === "PUSH") {
      setStack([...stack, pathname]);
    } else {
      setStack([...stack.slice(0, stack.length - 1), pathname]);
    }
  }, [pathname, type]);

  return stack;
}

export function useCanGoBack() {
  const navigate = useNavigate();
  const historyStack = useHistoryStack();

  const goBack = (to, options = {}) => {
    historyStack.length > 1 ? navigate(-1) : navigate(to, options);
  };
  return goBack;
}

export function useScrollTop() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    console.log("scroll top");
  }, []);

  return null;
}

export const useToggle = (initialState = false) => {
  // Initialize the state
  const [state, setState] = useState(initialState);

  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, setState, toggle];
};

function useIsFirstRender() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function useUpdateEffect(effect, deps) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useEffectOnce(effect) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export function useSingleAndDoubleClick(
  actionSimpleClick,
  actionDoubleClick,
  payload = {},
  delay = 250
) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick(payload);
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick(payload);

    return () => clearTimeout(timer);
  }, [click]);

  return () => setClick((prev) => prev + 1);
}

export function useBackendUrl() {
  const [user] = useLocalStorage("user", null);
  const backendUrl = useMemo(() => {
    const api = backendApis.find((el) => el.name === user?.company);
    return api.url;
  }, [user]);
  return backendUrl;
}

export function useToken() {
  const [user] = useLocalStorage("user", null);
  return user?.token;
}

export function useIsAdmin() {
  const [user] = useLocalStorage("user", null);
  return lowerCase(user?.Department) === "admin";
}

export function useIsSales() {
  const [user] = useLocalStorage("user", null);
  return ["sales", "sales manager"].includes(lowerCase(user?.Department));
}

export function useIsSalesManager() {
  const [user] = useLocalStorage("user", null);
  return ["sales manager"].includes(lowerCase(user?.Department));
}

export function useIsStore() {
  const [user] = useLocalStorage("user", null);
  return lowerCase(user?.Department) === "store";
}

export function useIsCashier() {
  const [user] = useLocalStorage("user", null);
  return (
    lowerCase(user?.Department) === "cashier" ||
    lowerCase(user?.Department) === "accountant"
  );
}

export function useIsInViewport(ref) {
  const options = {
    threshold: 1,
    rootMargin: "0px",
    root: null,
  };

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        options
      ),
    []
  );

  useEffect(() => {
    ref && observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
